<template>
   <div class="cont MessageBox">
      <!-- 面包屑 -->
      <el-row class="crumbs-box">
         <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>营销云</el-breadcrumb-item>
            <el-breadcrumb-item>运营</el-breadcrumb-item>
            <el-breadcrumb-item to="/activity_list/MESSAGE">短信活动</el-breadcrumb-item>
            <el-breadcrumb-item>{{ action === 'add' ? '创建' :  action === 'edit' ?  '编辑'  : '复制'}}短信活动</el-breadcrumb-item>
         </el-breadcrumb>
      </el-row>
      <el-page-header @back="$router.go(-1)" :content="`${ action === 'add' ? '创建' :  action === 'edit' ?  '编辑'  : '复制' }短信活动`"></el-page-header>
      <!-- 主体内容 -->
      <div class="cont-form-box">
         <!-- 步骤条 -->
         <el-steps :active="active" simple>
            <el-step title="01.活动基础配置"></el-step>
            <el-step title="02.活动内容预览"></el-step>
         </el-steps>
         <!-- 第一步 -->
         <div class="first-step" v-if="active === 0">
            <activity-config @nextStep="nextStep">
               <template v-slot:pushRule>
                  <el-form-item label="推送规则">
                        <el-radio v-model="ruleForm.Pushrules" label="ONCE">仅发送一次</el-radio>
<!--                         <el-radio v-model="ruleForm.Pushrules" label="USER_EVENT">基于用户事件触发</el-radio>-->
                        <!-- <el-radio v-model="ruleForm.Pushrules" label="3">周期性推送</el-radio>-->
                     <div v-if="ruleForm.Pushrules == 'ONCE'">
                        <el-select v-model="ruleForm.isWaitSend"  placeholder="请选择" class="margin-left-70">
                           <el-option
                                 v-for="(val, key) in { NO: '立即发送', YES: '定时发送' }"
                                 :key="key"
                                 :label="val"
                                 :value="key" >
                           </el-option>
                        </el-select>
                        <div class="margin-left-70" v-if="ruleForm.isWaitSend == 'YES'">
                           消息将于
                           <el-date-picker
                                 v-model="ruleForm.timeLong"
                                 type="datetime"
                                 placeholder="选择日期时间"
                                 value-format="yyyy-MM-dd HH:mm:ss">
                           </el-date-picker>
                           准时发送
                        </div>
                     </div>
<!-- 业务缺失
                     <div  v-if="ruleForm.Pushrules == 'USER_EVENT'">
                        <div>
                           从<el-date-picker v-model="beginDate" type="datetime" placeholder="选择开始时间" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
                           <el-date-picker v-model="endDate" type="datetime" placeholder="选择结束时间" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
                           时间范围内
                        </div>

                        <div>
                           当用户做过
                           <el-select v-model="triggerId"  placeholder="请选择">
                              <el-option
                                    v-for="item in dropDownbox "
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id">
                              </el-option>
                           </el-select>
                        </div>

                        <div>
                           <el-select v-model="isWaitSend" style="width: 10%" placeholder="请选择">
                              <el-option
                                    v-for="item in immediatelyAwait"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value" >
                              </el-option>
                           </el-select>
                           <span v-if="this.isWaitSend == 'NO'" style="font-size: 14px; color: #999">发送</span>
                           <template v-if="this.isWaitSend == 'YES'">
                               <el-input  style="width: 70px; margin-left: 1rem" v-model="time" placeholder="时间" ></el-input>
                               <el-select v-model="timedivision"  placeholder="请选择" style="width: 90px" >
                                  <el-option
                                       v-for="item in timedivisions"
                                       :key="item.value"
                                       :label="item.label"
                                       :value="item.value">
                                 </el-option>
                              </el-select>准时发送
                          </template>
                          <span>
                              活动期间，最多向用户展示
                              <el-select v-model="Shownumbers" style="width: 80px" placeholder="请选择" >
                                 <el-option
                                       v-for="item in Shownumber"
                                       :key="item.value"
                                       :label="item.label"
                                       :value="item.value" >
                                 </el-option>
                              </el-select>次
                           </span>
                           <div style="margin-top: 1rem">
                              <el-checkbox v-model="checked" >添加发送时间间隔：</el-checkbox>
                              <el-input-number v-model="num" @change="handleChange" :min="1" :max="100" ></el-input-number>
                              <el-select v-model="hour" style="width: 70px"  placeholder="小时" >
                                 <el-option v-for="item in hours"
                                            :key="item.value"
                                            :label="item.label"
                                            :value="item.value">
                                 </el-option>
                              </el-select>
                           </div>
                        </div>
                     </div>
-->
<!-- 业务缺失
                     <div v-if="ruleForm.Pushrules == 3">
                        <div>
                           从<el-date-picker v-model="beginDate" type="datetime" placeholder="选择开始时间"  value-format="yyyy-MM-dd HH:mm:ss"> </el-date-picker>到
                           <el-date-picker  v-model="endDate" type="datetime"  placeholder="选择结束时间"  value-format="yyyy-MM-dd HH:mm:ss" > </el-date-picker> 时间范围内
                        </div>
                        <div>
                           <el-popover placement="bottom" width="200" trigger="click">
                             <el-input slot="reference" style="width: 130px"  v-model="everyday"  ></el-input>
                             <el-row  v-for="item in datesa"  :key="item.value"  style="cursor: pointer; line-height: 40px" >{{ item.label }}</el-row>
                             <el-row style="cursor: pointer; margin-top: 1rem" ><p @click="custom()">自定义...</p></el-row>
                           </el-popover>
                            <el-time-picker v-model="hourminutesecond" :picker-options="{ selectableRange: '18:30:00 - 20:30:00' }"  placeholder="任意时间点" ></el-time-picker>发送
                           <div>默认第一次发送时间为2021/01/21</div>
                        </div>
                     </div>
-->
                  </el-form-item>
               </template>
            </activity-config>
         </div>
         <!-- 第二步 -->
         <el-row v-if="active === 1">
            <el-col :span="8">
               <el-form  :model="previewForm">
                  <el-form-item label="模板">
                     <el-select v-model="previewForm.msgTemplateId" placeholder="请选择模板"  class="width-300" @change="templatevalue">
                        <el-option v-for="item in templatelist"
                              :key="item.id"
                              :label="item.name"
                              :value="item.id">
                        </el-option>
                     </el-select>
                  </el-form-item>
                  <el-form-item label="短信内容">
                     <el-input type="textarea"  class="width-300" :rows="4" placeholder="请输入内容" v-model="previewForm.content" />
                     <el-input  type="textarea" class="width-300 margin-left-70" :rows="2" placeholder="实际发送时，如有模板变量会影响计费条数，请注意！" disabled></el-input>
                  </el-form-item>
               </el-form>
            </el-col>
            <el-col :span="16">
               <el-row>
                  <el-col :span="12" class="preview">
                     <el-row class="preview-bg">
                        <div class="usermessage">
                           <div class="headportrait"></div>
                           <div class="advices">{{previewForm.content}}</div>
                        </div>
                     </el-row>
                  </el-col>
                  <el-col :span="12" class="information">
                     <ul>
                        <li><span class="c-grey">活动名称：</span>{{ruleForm.eventName}}</li>
                        <li><span class="c-grey">目标用户：</span>{{groupLabel.join('、') }}</li>
                        <li><span class="c-grey">推送规则：</span>{{ ruleForm.Pushrules | filterPushRule}}</li>
                        <li>
                           <el-button style="margin-top: 12px" @click="active --" >上一步</el-button>
                           <el-button class="bg-gradient"  @click="handleSave">开始活动</el-button>
                           <el-button class="bg-gradient" type="primary"  @click="handleSave('DRAFT')">保存草稿</el-button>
                        </li>
                     </ul>
                  </el-col>
               </el-row>
            </el-col>
         </el-row>
      </div>

<!--
      <el-dialog title="自定义频率" :visible.sync="dialogTableVisible">
         <el-row style="text-align: center">
            每<el-input-number   v-model="num" @change="handleChange" :min="1"  :max="10"  label="描述文字" ></el-input-number>
            <el-select  v-model="Weekmonths" placeholder="请选择"  style="width: 70px" >
               <el-option
                     v-for="item in Weekmonth"
                     :key="item.value"
                     :label="item.label"
                     :value="item.value">
               </el-option>
            </el-select>重新发送
         </el-row>
         <el-row v-show="this.Weekmonths == 'WEEK'">周内循环</el-row>
         <el-row class="btns" v-show="this.Weekmonths == 'WEEK'">
            <el-button type="primary" plain>一</el-button>
            <el-button type="primary" plain>二</el-button>
            <el-button type="primary" plain>三</el-button>
            <el-button type="primary" plain>四</el-button>
            <el-button type="primary" plain>五</el-button>
            <el-button type="primary" plain>六</el-button>
            <el-button type="primary" plain>天</el-button>
         </el-row>
         <el-row style="margin-top: 1rem; text-align: center">
            <el-button >取消</el-button>
            <el-button type="primary" >确认</el-button>
         </el-row>
      </el-dialog>
      -->
   </div>
</template>

<script>
import { urlObj  } from '@/api/interface';
import { operate } from  "@/api/interface/smtech";
import { label } from "@/api/interface/data";
import { mapState } from 'vuex';
import activityConfig from '@/components/local/activityConfig';
export default {
   name: "createMessage",
   components:{ activityConfig },
   computed: { ...mapState(['hotelInfo']) },
   watch: {
      hotelInfo: {
         handler(newVal, oldVal) {
            if (newVal.id !== oldVal.id && oldVal) {
               this.hotelId = this.hotelInfo.id
               this.formboarda();
               // this.Goal();
               // this.wechatlabel();
            }
         },
      }
   },
   data(){
      return{
         hotelId:'',
         action:'',
         active:0,
         ruleForm:{
            satat:'IN_PROGRESS',
            Pushrules:'ONCE',
            isWaitSend:'NO',
            timeLong:''
         },
         previewForm:{
            msgTemplateId:'',     // 模板 需要修改为对应id
            content:''  // 短信内容
         },
         templatelist:[],
         groupOpt:[],        // 获取群组列表
         groupLabel:[],
         platform:'',
      }
   },
   mounted() {
      this.action = this.$route.query.act;
      this.hotelId = this.hotelInfo.id
      let path = this.$route.path
      this.platform = path.substring(path.indexOf("_") + 1).toLocaleUpperCase()
      this.formboarda();
      this.getGroupList()
      if(this.action !== 'add') this.getEditInfo()
   },
   methods:{
      // edit/copy回显
      getEditInfo(){
         const activityObj = JSON.parse(sessionStorage.getItem('activityObj') || '{}')
         for (const k in this.previewForm) k in activityObj && ( this.$nextTick(()=>{ this.previewForm[k] = activityObj[k] }) )
      },
      // 开始活动
      handleSave(state){
         if (state === 'DRAFT') this.ruleForm.state = state
         let param = {
            companyId: this.hotelInfo.storeId,
            hotelId: this.hotelId,
            state: this.ruleForm.state,
            platform: this.platform , // 当前类型
            eventName: this.ruleForm.eventName,
            isConvert: this.ruleForm.isConvert, // 转化跟踪
            groupIds: this.ruleForm.groupList.map(item => item.groupId).toString(), // 触发群组
            pushRule: this.ruleForm.Pushrules,// 推送规则
            isWaitSend: this.ruleForm.isWaitSend, // 立即/定时发送
            msgTemplateId: this.previewForm.msgTemplateId, // 模板
            content: this.previewForm.content, //短信消息
         }
         if(this.ruleForm.isConvert === 'YES'){
            param.targetId = this.ruleForm.targetId
            param.days = this.ruleForm.days
         }
         if(this.ruleForm.isWaitSend === 'YES') param.timeLong = this.ruleForm.timeLong
         let url = (this.action === 'edit' && this.ruleForm.state !== 'DRAFT' ? operate.editActivity : operate.createActivity)
         if (this.action === 'edit' && this.ruleForm.state !== 'DRAFT') param.id = this.ruleForm.id
         this.$axios.post(url, param).then((res) => {
            if (res.status === 200) {
               this.$message({
                  message: "创建成功!",
                  type: "success",
               });
               this.goBack()
            }
         });
      },

      // 获取群组列表
      getGroupList() {
         const url = label.tagGroupList
         const param = { companyId: this.hotelInfo.storeId }
         this.$axios.post(url, param).then(res => {
            if (!res.success) return
            let data = res.records
            this.groupOpt = data.map(item => ({ label: item.groupName, value: item.id }))
         })
      },

      // 获取模板列表
      formboarda() {
         const url = urlObj.templateList;
         const param = {
            limit: "20",
            page: "1",
         };
         this.$axios.post(url, param).then((res) => {
            this.templatelist = res.records;
         });
      },


      // 模板发生变化时
      templatevalue(){
         this.templatelist.map((item) => {
            if (this.previewForm.msgTemplateId == item.id) this.previewForm.content = item.content;
         });
      },

      // 检验活动名称是否重复
      checkNameIsExist() {
         return new Promise((resolve) => {
            const url = operate.checkNameIsExist
            const param = { eventName: this.ruleForm.eventName, companyId: this.hotelInfo.storeId }
            this.$axios.post(url, param).then(res => {
               if (!res.success) return resolve(true);
               resolve(res.records)
            })
         })
      },
      // 下一步
      async nextStep(ruleForm){
         this.ruleForm = Object.assign({}, this.ruleForm, ruleForm)

         if(this.action !== 'edit'){
            if (await this.checkNameIsExist()) {
               return this.$message.error('活动名称不能重复')
            }
         }
         this.active ++
         // 到第二步时回显 预览的触发群组
         if(this.active === 1) {
            this.groupLabel = []
             this.groupOpt.forEach(item=>{
               this.ruleForm.groupList.forEach(items=>{
                  if(items.groupId === item.value)  this.groupLabel.push(item.label)
               })
            })
         }
      },
      // 回退上页
      goBack(){
         this.$router.go(-1)
      },


      //自定义
      custom() {
         // this.dialogTableVisible = true;
      },
   },
   filters:{
      filterPushRule(val){
         switch(val){
            case 'ONCE' :
               return "仅发送一次"
            case "USER_EVENT" :
               return "基于用户事件触发"
            case "3" :
               return "周期性推送"
         }
      },

   }

}
</script>

<style scoped lang="scss">
.MessageBox{
   .preview{
      float: left;
      height: 615px;
      background: #e5e5e5;
      &-bg {
         width: 79%;
         margin: auto;
         position: relative;
         height: 100%;
         background: url("../../../../assets/bgphone2.45cc4e0.png") 0px 0px no-repeat;
         background-size: cover;
         .usermessage {
            position: absolute;
            top: 20%;
            left: 10%;
            display: flex;
            .headportrait {
               height: 65px;
               min-width: 65px;
               background: url("../../../../assets/下载.png") 0px 0px no-repeat;
            }
            .advices {
               width: 64%;
               height: auto;
               background: #fff;
               box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.13);
               border-radius: 10px;
               line-height: 25px;
               font-size:15px ;
               padding: 10px;
               margin-left: 5px;
            }
         }
      }
   }
   .information{
      ul {
         list-style: none;
         margin-top: 3rem;
         margin-left: 3rem;
         li {
            line-height: 40px;
            span {
               font-size: 14px;
            }
         }
      }
   }

   .margin-left-70 {margin: 10px 0 0 70px;}
}
</style>
